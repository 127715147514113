<template>
	<div>
		<div class="container portfolio">
			<div class="tabs">
				<VuePerfectScrollbar class="tabs__header" v-if="groupQuantity > 1" :settings="scrollSettings">
					<a class="tabs__header__item" v-for="(item, index) in grouped" :data-tab="index" :class="{active: activeTab == index}" @click.prevent="setTab($event, index)" :key="index">
						{{index}}
					</a>
				</VuePerfectScrollbar>
				<div class="tabs__body">
					<transition-group name="slide" tag="div">
						<div class="tabs__body__item" v-for="(item, index) in grouped" :key="index" v-if="activeTab == index" :data-tab="index">

							<div class="fullPageSectionContainer" v-for="(slide, key) in item.slice(0, page * 5)" :key="key">

								<div class="fullPageSection">
									<div class="projectSliderContainer2">
										<div class="projectSlider__header hide1200">
											<div class="projectSlider--title h2 animationBlockFadeUp"><p v-html="slide.title"></p></div>
										</div>
										<div class="projectSlider__item--up animationBlockFadeUp">
											<router-link :to="slide.link" class="projectSlider__item" :class="[{imageHoverSlide: slide.sliderType == `slider`}, {videoSlide: slide.sliderType == `video`}, slide.sliderClass]" :data-slide="key" @mouseenter.native="infoClass = 'active'" @mouseleave.native="infoClass = ''" @mousemove.native="mouseMove($event)" v-if="slide.link">

												<div class="projectSlider__overlaySvg" v-if="slide.sliderImageOverlaySvg" v-html="slide.sliderImageOverlaySvg">
												</div>

												<span class="projectSlider__img" v-lazy:background-image="slide.sliderImageSrc" v-if="slide.sliderType == `hover`">
													<span class="projectSlider__imageHover" v-lazy:background-image="slide.sliderImageHoverSrc"></span>
												</span>

												<span class="projectSlider__img" v-lazy:background-image="slide.sliderImageSrc" v-else-if="slide.sliderType == `slider`">
													<span class="projectSlider__imageHoverSlide">
														<span class="projectSlider__imageHoverSlide--image" v-lazy:background-image="slide.sliderImageHoverSrc"></span>
													</span>
												</span>

												<span class="projectSlider__video" v-else-if="slide.sliderType == `video` && !isMobile">
													<video :src="slide.sliderVideoSrc" muted="muted" autoplay playsinline loop="loop"></video>
												</span>
												
												<span class="projectSlider__img" v-lazy:background-image="slide.sliderImageSrc" v-else-if="slide.sliderType == `video`">
												</span>
									
												<span class="projectSlider__img" v-lazy:background-image="slide.sliderImageSrc" v-else-if="slide.sliderType == `image`"></span>

												<span class="projectSlider__img" v-else-if="slide.sliderType == `imageInner`">
													<img v-lazy="slide.sliderImageSrc">
												</span>
												
												<span class="projectSlider__img randomPosition noRandom" v-else-if="slide.sliderType == `randomPosition`">
													<img v-lazy="slide.sliderImageSrc">
												</span>

												<span class="projectSlider__img" v-lazy:background-image="slide.sliderImageSrc" v-else>
												</span>

												<div class="projectMouse" v-if="!mobileDevice">
													Перейти
												</div>

											</router-link>
											<div class="projectSlider__item" :class="[{imageHoverSlide: slide.sliderType == `slider`}, {videoSlide: slide.sliderType == `video`}, slide.sliderClass]" :data-slide="key" @mousemove="mouseMove($event)" @mouseenter="infoClass = 'active'" @mouseleave="infoClass = ''"  v-else>

												<div class="projectSlider__overlaySvg" v-if="slide.sliderImageOverlaySvg" v-html="slide.sliderImageOverlaySvg">
												</div>

												<span class="projectSlider__img" v-lazy:background-image="slide.sliderImageSrc" v-if="slide.sliderType == `hover`">
													<span class="projectSlider__imageHover" v-lazy:background-image="slide.sliderImageHoverSrc"></span>
												</span>

												<span class="projectSlider__img" v-lazy:background-image="slide.sliderImageSrc" v-else-if="slide.sliderType == `slider`">
													<span class="projectSlider__imageHoverSlide">
														<span class="projectSlider__imageHoverSlide--image" v-lazy:background-image="slide.sliderImageHoverSrc"></span>
													</span>
												</span>

												<span class="projectSlider__video" v-else-if="slide.sliderType == `video` && !isMobile">
													<video :src="slide.sliderVideoSrc" muted="muted" autoplay playsinline loop="loop"></video>
												</span>

												<span class="projectSlider__img" v-lazy:background-image="slide.sliderImageSrc" v-else-if="slide.sliderType == `video`">
												</span>
									
												<span class="projectSlider__img" v-lazy:background-image="slide.sliderImageSrc" v-else-if="slide.sliderType == `image`"></span>

												<span class="projectSlider__img" v-else-if="slide.sliderType == `imageInner`">
													<img v-lazy="slide.sliderImageSrc">
												</span>
												
												<span class="projectSlider__img randomPosition noRandom" v-else-if="slide.sliderType == `randomPosition`">
													<img v-lazy="slide.sliderImageSrc">
												</span>

												<span class="projectSlider__img" v-lazy:background-image="slide.sliderImageSrc" v-else>
												</span>

											</div>
										</div>

										<div class="projectSlider__tableInfo show1200">
											<div class="projectSlider__header">
												<div class="projectSlider--title h2 animationBlockFadeUp"><p v-html="slide.title"></p></div>
												<div class="projectSlider__info animationBlockFadeUp" :class="infoClass">
													<router-link :to="slide.link" class="projectSlider__info" v-if="slide.link">Подробнее</router-link>
												</div>
											</div>
											<div class="projectSlider--desc animationBlockFadeUp"><span v-html="slide.text"></span></div>
										</div>

										<div class="projectSlider__footer animationBlockFadeUp hide1200">
											<div class="projectSlider--desc"><span v-html="slide.text"></span></div>
											<div class="projectSlider__info animationBlockFadeUp" :class="infoClass">
												<router-link :to="slide.link" class="projectSlider__info" v-if="slide.link">Подробнее</router-link>
											</div>
										</div>
									</div>
								</div>
							</div>
							
						</div>
					</transition-group>
				</div>
			</div>
		</div>

		<div class="showMoreButton" @click.prevent="showMore()" v-if="grouped && page * 5 < grouped[activeTab].length">
			<a>Загрузить еще проекты</a>
		</div>
	</div>
</template>




<style lang="sass">
	@import '@/assets/new_sass/Portfolio'
	@import '@/assets/new_sass/ProjectSlider'
</style>

<script>
	import VuePerfectScrollbar from 'vue-perfect-scrollbar'
	import 	projectsData from "@/assets/json/projects.json";

	export default {
		name: 'portfolio',
		head () {
			return {
				link: [
					{ rel: 'canonical', href: 'https://artrockets.ru' + this.$route.path + '/' }
				]
			}
		},
		data: () => ({
			grouped: null,
			groupQuantity: 0,
			activeTab: "Все",
			title: "",
			desc: "",
			url: "",
			projectsData,
			projectsItems: [],
			page: 1,
			infoClass: "",
			isMobile: false,
			mobileDevice: false,
			scrollSettings: {
				suppressScrollY: true
			},
			// randomPositionInterval: null
		}),
		metaInfo() {
			return {
				title: this.activeTab === "Все" ? 'Портфолио веб-студии | «‎Артрокетс»' : `${this.activeTab} | «‎Артрокетс»`,
				meta: [
					{ vmid: 'description', name: 'description', content: 'Выполненные проекты компании Артрокетс. Корпоративные сайты и интернет-магазины, а также онлайн сервисы.' },
					{ vmid: 'og:title', property: 'og:title', content: 'Портфолио веб-студии | «‎Артрокетс»' },
					{ vmid: 'og:description', property: 'og:description', content: 'Выполненные проекты компании Артрокетс. Корпоративные сайты и интернет-магазины, а также онлайн сервисы.' },
				],
			}
		},
		beforeMount(){
			this.initSlides();
		},
		mounted() {
			var comp = this;
			if(this.projectsItems[0]){
				this.title = this.projectsItems[0].title;
				this.desc = this.projectsItems[0].text;
				this.url = this.projectsItems[0].link;
			}

			comp.videoHeight();
			comp.resetSizes();
			comp.groupProjects();
			comp.setIsMobile();
			// comp.setRandomPosition();

			window.addEventListener('resize', function(){
				comp.videoHeight();
				comp.resetSizes();
				comp.setIsMobile();
			});

			setTimeout(function(){
				comp.videoHeight();
				comp.resetSizes();
			}, 1000);

			if(this.grouped[this.$route.query.type]) {
				this.setTab({target: {dataset: {tab: this.$route.query.type || "Все"}}}, this.$route.query.type || "Все");
			}

			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
				comp.mobileDevice = true;
			}
		},
		methods: {
			groupBy: function(array, key){
				const result = {}
				result["Все"] = this.projectsItems;

				array.forEach(item => {
					if (!result[item[key]]){
						result[item[key]] = []
					}
					result[item[key]].push(item)
				})
				return result
			},
			groupProjects: function(){
				this.grouped = this.groupBy(this.projectsItems, 'category');
				this.groupQuantity = Object.keys(this.grouped).length;
				var activeTab = Object.keys(this.grouped)[0];
				if(this.$route.query.tab){
					activeTab = this.$route.query.tab;
				}

				this.activeTab = activeTab;
				this.fullQuantity = this.grouped[this.activeTab].length;
			},
			setIsMobile: function(){
				if(document.body.clientWidth > 600){
					this.isMobile = false;
				} else{
					this.isMobile = true;
				}
			},
			setTab: function(event, title){
				this.activeTab = event.target.dataset.tab;

				this.$router.push(`${location.pathname}?type=${title}`);

				this.page = 1;
				this.videoHeight();
				this.resetSizes();
				this.animate();
			},
			showMore: function(){
				var comp = this;

				this.page = this.page + 1;
				setTimeout(function(){
					comp.videoHeight();
					comp.resetSizes();
					window.dispatchEvent(new CustomEvent('scroll'))
				}, 100);


			},
			animate: function(){
				setTimeout(function(){
					var scrollTop = document.documentElement.scrollTop;
					var windowHeight = window.innerHeight;
					var windowBottomPos = scrollTop + windowHeight;

					var blocks = document.getElementsByClassName("animationBlockFadeUp");
					blocks.forEach(function(element, index) {
						var elementPosTop = element.getBoundingClientRect().top + document.documentElement.scrollTop;

						if(windowBottomPos > elementPosTop + 100){
							setTimeout(function(){
								element.style.transform = 'matrix(1, 0, 0, 1, 0, 0)';
								element.style.opacity = '1';
							}, 500);
						}
					});
				}, 500);
			},
			initSlides: function(){
				var comp = this;

				comp.projectsData.forEach( function(element, index) {

					if(element.showInNewTemplate){
						if(element.sliderImage){
							element.sliderImageSrc = require(`@/assets/img_new/${element.sliderImage}`);
						}
						if(element.sliderImageHover){
							element.sliderImageHoverSrc = require(`@/assets/img_new/${element.sliderImageHover}`);
						}
						if(element.sliderVideo){
							element.sliderVideoSrc = require(`@/assets/img_new/${element.sliderVideo}`);
						}
						comp.projectsItems.push(element);
					}
				});
			},
			mouseMove: function(event){
				var target = event.target;
				var element;

				if(target.classList.contains('imageHoverSlide')){
					element = target;
				} else{
					element = target.closest('.projectSlider__item');
				}

				if(target.classList.contains('imageHoverSlide') || (target.closest('.projectSlider__item') && target.closest('.projectSlider__item').classList.contains('imageHoverSlide'))){
					var image = element.getElementsByClassName("projectSlider__img")[0];
					var imageSlide = element.getElementsByClassName("projectSlider__imageHoverSlide")[0];

					var mousePos = event.pageY;

					var bodyRect = document.body.getBoundingClientRect(),
					elemRect = element.getBoundingClientRect();
					var blockTop = elemRect.top - bodyRect.top;

					var height = image.offsetHeight;

					var result = (mousePos - blockTop) / height * 100;
					imageSlide.style.height = result + "%";
				}

				if(!this.mobileDevice){
					var mouseElement = element.getElementsByClassName("projectMouse")[0];

					var targetCoords = element.getBoundingClientRect();
					var xCoord = event.clientX - targetCoords.left;
					var yCoord = event.clientY - targetCoords.top;

					setTimeout(function(){
						mouseElement.style.top = yCoord + "px";
						mouseElement.style.left = xCoord + "px";
					}, 100);
				}
			},
			videoHeight: function(){
				var comp = this;
				setTimeout(function(){
					var videoContainers = comp.$el.getElementsByClassName("videoSlide");
					
					Array.from(videoContainers).forEach(function(el, index){
						var video = el.getElementsByTagName("video")[0];
						video.style.height = 'auto';
						video.style.width = 'auto';

						var containerHeight = el.offsetHeight;
						var containerWidth = el.offsetWidth;
						var videoHeight = video.offsetHeight;
						var videoWidth = video.offsetWidth;

						if(containerHeight/videoHeight > containerWidth/videoWidth){
							video.style.height = (containerHeight+10) + "px";
						} else{
							video.style.width = (containerWidth+10) + "px";
						}
					});
				});
			},
			resetSizes: function(){
				var elements = document.getElementsByClassName("projectSlider__imageHoverSlide--image");
				Array.from(elements).forEach( function(element, index) {
					var parent = element.closest(".projectSlider__item");
					var parentHeight = parent.offsetHeight;
					element.style.height = parentHeight + "px";
				});
			}/*,
			setRandomPosition: function(){
				var comp = this;

				clearInterval(comp.randomPositionInterval);
				comp.randomPositionInterval = null;
				comp.randomPositionInterval = setInterval(function(){
					var elements = document.getElementsByClassName("randomPosition");

					elements.forEach( function(element, index) {
						var image = element.getElementsByTagName("img")[0];
						var parent = element.closest(".projectSlider__item");
						var parentHeight = parent.offsetHeight - 40;
						var parentWidth = parent.offsetWidth - 40;
						image.style.maxHeight = "inherit";
						image.style.maxWidth = "inherit";
						var elementHeight = image.offsetHeight;
						var elementWidth = image.offsetWidth;

						if(elementHeight > parentHeight){
							elementHeight = parentHeight;
						}

						if(elementWidth > parentWidth){
							elementWidth = parentWidth;
						}

						var height = Math.random() * elementHeight;
						if(height < 250){
							height = 250;
						}

						var width = Math.random() * elementWidth;
						if(width < 250){
							width = 250;
						}

						var top = Math.random() * (parentHeight - elementHeight) + 20;
						var left = Math.random() * (parentWidth - elementWidth) + 20;

						image.style.maxHeight = height + "px";
						image.style.maxWidth = width + "px";

						if(Math.random() < 0.5){
							image.style.bottom = "auto";
							image.style.top = top + "px";
						} else{
							image.style.top = "auto";
							image.style.bottom = top + "px";
						}

						if(Math.random() < 0.5){
							image.style.right = "auto";
							image.style.left = left + "px";
						} else{
							image.style.left = "auto";
							image.style.right = left + "px";
						}
					});
				}, 3000);
			}*/
		},
		components: {
			Portfolio: () => import('@/components/new/Portfolio'),
			VuePerfectScrollbar
		}
	}
</script>
